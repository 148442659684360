import React from 'react';

import ComingSoon from './components/ComingSoon';
import Layout from 'src/common/components/Layout';

const IndexPage = () => (
  <Layout>
    <ComingSoon />
  </Layout>
);

export default IndexPage;
